import React, { Component } from 'react';

import NavbarHeader from '../components/navbarHeader';


export default class Success extends Component {

    constructor(props){
        super(props)
    }

    componentDidMount(props){
          var cu = JSON.parse(localStorage.getItem('curruntUser'));
      
          if(!cu){
                  this.props.history.replace('/');
          } 
      }
    render(){

        return(
        <React.Fragment>
        <NavbarHeader manage={this.props}/>
        <div className="successText">
            <h4>Success!</h4>
        </div>

        <div>
            <p>You'll receive a confirmation<br></br> email shortly.</p>
        </div>
        <div>
        <button className="returnBtn">
                Tap back button to return to Estate Clarity
                </button>
        </div>
        </React.Fragment>
        );
    }
}
