import {pass_to_paymentInfo, pass_to_checkoutForm, pass_to_cancelSubscription} from '../actions/actionTypes'
import {combineReducers} from 'redux'

const initialState = {}
const estateReducer = (state=initialState,action)=>{
    switch(action.type){
        case pass_to_paymentInfo: return {
            ...state,
            data: action.data
        }

        case pass_to_checkoutForm: return{
            ...state,
            value:action.data.value,
            status:action.data.status
        }

        case pass_to_cancelSubscription:return{
            ...state,
            estateData:action.data
        }

        default: return state
    }
}


export const rootreducer = combineReducers({
    estateReducer
})