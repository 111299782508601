import React, {Component} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import Loader from 'react-loader-spinner';
import Swal from 'sweetalert2';
import {
  withRouter
} from "react-router-dom";
import {connect} from 'react-redux';
import {apiService} from '../services/api';
class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {

      loader:false

    };
    this.submit = this.submit.bind(this);

    
  }

  async submit(event) {
    event.preventDefault();
    // console.log('pay', this.props);

    let {token} = await this.props.stripe.createToken({name: "Name"});

    // console.log(token);

    this.setState({loader:true});

    if(token!='' && token!=null && token!=undefined){
      // alert(this.props.data.value);
      let amount = this.props.data.value;
      let estateId = this.props.data.data.id;
      let paymentMode;
      if(this.props.data.status == 0){
        paymentMode = 'monthly'
      } else {
        paymentMode = 'annual'
      }
  
  
      // console.log(JSON.stringify(token),amount,  estateId);
      apiService.estatePayment(amount,estateId, paymentMode,token,).then(res => { 
  
        // console.log(res);

        if(res.statusText === 'OK'){
          this.setState({loader:false});
          // console.log("props-----", this.props);
          this.props.history.push('/success');  
        } else {
          Swal.fire({
            type: "error",
            title: "Oops...",
            text:
              "Server doesn't respond please try later.",
            confirmButtonColor: "#62A3F9"
          });
        }
  
      }).catch((error) => {
        this.setState({loader:false});
        console.log(error); 
        Swal.fire({
          type: "error",
          title: "Oops...",
          text:
            "Server doesn't respond please try later.",
          confirmButtonColor: "#62A3F9"
        });
      });
    } else {
      this.setState({loader:false});
      Swal.fire({
        type: "error",
        title: "Oops...",
        text:
          "Please Enter Valid Card Details",
        confirmButtonColor: "#62A3F9"
      });

    }

    
   
  }



  render() {

    // console.log("props----", this.props);
    return (
      <div className="checkout">
        <p>Would you like to complete the purchase?</p>

        <CardElement />

        

        <div className="userHomeText">
                <p>
                 By clicking the pay button on this page, I agree to the <span className="change_color">terms and services.</span>
                </p>
              </div>
             { this.state.loader && <div className="proccessing_sec">
              <div className="proccessing_div">
              <div><p>Processing...</p></div>
              <Loader type="Oval" color="#62A3F9" height={80} width={80} />
              </div>

              </div>}

        <button className="payBtn" onClick={this.submit}>
                  Pay
                </button>
        {/* <button>Send</button> */}
      </div>
    );
  }
}
const mapStateToProps = (state) =>{
  // console.log(state);
  return {data:state.estateReducer};
}
export default connect(mapStateToProps)(injectStripe(withRouter(CheckoutForm))) 
