import React, { Component }from 'react';

import { Col, Row, Button, Form, FormGroup, Label, Input, FormText, CustomInput } from 'reactstrap';

import NavbarHeader from './navbarHeader';

import {apiService} from '../services/api';
import BackArrow from '../images/backArrow.png';

import {connect} from 'react-redux'
import {paymentData} from '../actions/actions'
import { Spinner } from 'reactstrap';
import Swal from 'sweetalert2';
import * as moment from 'moment';

import {
  withRouter
} from "react-router-dom";

import {
    BrowserRouter ,
    Route,
    Switch
  } from "react-router-dom";

 class CancelSubscription extends Component{
      constructor(props){
          super(props);

          this.state = {
          
            loaderShow:false
          }

          // console.log(this.props.paidEstatedata);
      }

      
    componentDidMount(props){
      // console.log("paidUser------------", this.props.paidEstatedata);
        var cu = JSON.parse(localStorage.getItem('curruntUser'));
    
        if(!cu){
                this.props.history.replace('/');
        } 
    }


    goBackHandle = () =>{
        // console.log(this.props);

        this.props.history.push('/manageEstates');
       
    }


    cancelSubscription = () =>{
        Swal.fire({
           
            text: "Cancelling your subscription will prevent you and all collaborators from accessing this estate's data at the end of the subscription period.",
            title: "we'll be sorry to see you go!",
            showCancelButton: true,
            confirmButtonColor: '#62A3F9',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm Cancellation',
            cancelButtonText: 'Dismiss'            
          }).then((result) => {
            if (result.value) {
              Swal.fire(
                'Dismiss!',
                'Your Subscription has been Cancelled.',
                'success'
              )
            }
          })

    }

      render(){
          return(
            <React.Fragment>
            <NavbarHeader params={this.props} />
            
            <div className="payment_main_div"> 
            <div className="backButton">
                <button onClick={this.goBackHandle}><img src={BackArrow}/> Back</button>
            </div>
            {this.props.paidEstatedata.payment_mode=='monthly' && <div className="userHomeText">
              <p>
                {this.props.paidEstatedata.name} Estate is currently enrolled in the {this.props.paidEstatedata.payment_mode} payment plan.
                The next billing date is {moment(this.props.paidEstatedata.payment_date).add(1, 'month').format('MMMM Do YYYY, h:mm:ss a')}
              </p>
            </div>}

            {this.props.paidEstatedata.payment_mode=='annual' && <div className="userHomeText">
              <p>
                {this.props.paidEstatedata.name} Estate is currently enrolled in the {this.props.paidEstatedata.payment_mode} payment plan.
                The next billing date is {moment(this.props.paidEstatedata.payment_date).add(1, 'year').format('MMMM Do YYYY, h:mm:ss a')}
              </p>
            </div>}
            {/* <div>
            {this.state.loaderShow && <Spinner size="sm" color="primary" />}
            </div> */}
            <div>
            <button className="payBtn" onClick={this.cancelSubscription}>
                  {/* Update billing & payment Info */}
                  Cancel Subscription
            </button>
            </div>
            &nbsp;
             &nbsp;   
             &nbsp;   
             &nbsp;   
            {/* <div>
                <p onClick={this.cancelSubscription}><b>Cancel Subscription</b></p>
            </div> */}
            &nbsp;
             &nbsp;   
             &nbsp;   
             &nbsp;   
             &nbsp;   

            <div className="homeBottomText">
                <p>If you need assistance managing your estates or payments please reach out to <a  target="_blank" href='mailto:hello@estateclarity.com'>hello@estateclarity.com</a></p>
            </div>
            </div>
          </React.Fragment>
          );
      }
  }


  const mapStateToProps = (state) =>{
    // console.log(state)
    return {paidEstatedata:state.estateReducer.estateData};
  }

  export default connect(mapStateToProps)(withRouter(CancelSubscription))