import axios from 'axios';

const BaseUrl = 'https://dev.estateclarity.com/';  

const serverBucketUrl = 'https://es-clarity.s3.amazonaws.com/uploads/';

const apiService = {

    login:(email, password, cb) =>{
        var getTokenData = JSON.parse(localStorage.getItem('token'));

        var headers = {
            header: {
                'Content-Type': 'application/json'
            }
        };
        axios.post(BaseUrl+'api/sessions', {
            "session": {
                "email": email,
                "password": password
            }
        },headers)
        .then( (response, error) => {
            cb(response)
        })
        .catch(function (error) {
            // console.log(error);
            cb(error)
          });
    },

    forgotPassword:(cb)=>{
        var headers = {
            header: {
                'Content-Type': 'application/json'
            }
        };
        var getTokenData = JSON.parse(localStorage.getItem('token'));

        axios.post(BaseUrl+'api/forgot_password', headers)
        .then( (response, error) => {
            cb(response)
        })
        .catch(function (error) {
            // console.log(error);
            cb(error)
          });
        
    },

    getEstatesFromDb : (cb) => {
        var getTokenData = JSON.parse(localStorage.getItem('token'));

        var UserID = JSON.parse(localStorage.getItem('userId'));
        
        var headers  = {
          'Authorization':'Bearer '+getTokenData
        }
        axios.get(BaseUrl+'api/estates', {headers})
          .then( (response) => {
    
            // console.log("res------------", response);
            // console.log(response['data'], "getEstates-----------");
            // console.log(response['data'].data);
    
            
            cb(response)
          })
          .catch(function (error) {
            // console.log(error);
          });
    },

    estatePayment:(amount, estateId,paymentMode, token) => {
        return new Promise((resolve,reject)=>{ 
        // console.log(amount, estateId,paymentMode, token.id);
        var getTokenData = JSON.parse(localStorage.getItem('token'));
        
        var headers  = {
            'Authorization':'Bearer '+getTokenData
          }
          axios.post(BaseUrl+'api/estates/pay',{
            "estate_id":estateId,
            "amount":amount,
            "currency": "USD",
            "token": token.id,
            "payment_mode":paymentMode
        },  {headers})
            .then( (response) => {
      
            //   console.log("res------------", response);
            resolve(response);
            })
            .catch(function (error) {
                reject(error.response);
            //   console.log(error.response);
            });
        });
    }
}

export  {apiService, serverBucketUrl};