import React , { Component } from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input, FormText, CustomInput } from 'reactstrap';

import NavbarHeader from './navbarHeader';

import {apiService} from '../services/api';

import {connect} from 'react-redux'
import {paymentData} from '../actions/actions'
import { Spinner } from 'reactstrap';
import {
    BrowserRouter ,
    Route,
    Switch
  } from "react-router-dom";

 class Home extends Component{
  
    constructor(props){

        super(props)
        this.state = {
          estates:[],
          loaderShow:false,
          userName:''
        }

    }

    componentDidMount(props){
      var cu = JSON.parse(localStorage.getItem('curruntUser'));

      var CurrentUserName = JSON.parse(localStorage.getItem('curruntUserName'));

      this.setState({userName:CurrentUserName});

      // console.log(userName);

      if(!cu){
              this.props.history.replace('/');
      }
      
      this.getAllEstates();
     
  }


  getAllEstates = () => {
    this.setState({loaderShow:true});
    apiService.getEstatesFromDb((res) => {
      if(res){
      // console.log("test1..........,", res['data'].data);
      this.setState({estates:res['data'].data});
      this.setState({loaderShow:false});
      }
    });
  }

    goToPaymentInfo = (data) => {

      // console.log(data,'home1');
      this.props.payment(data)
      
        this.props.history.replace('/paymentInfo');
    }
    render(){
        return (
          <React.Fragment>
            <NavbarHeader params={this.props} />
            <div className="payment_main_div"> 
            <div className="userHomeText">
              <p>Hi {this.state.userName},</p>
              <p>
                You can enroll any of the following estates into a paid
                plan.
              </p>
            </div>
            <div>
            {this.state.loaderShow && <Spinner size="sm" color="primary" />}
            </div>
            <div className="checkboxInput">
              <ul>
             
                {this.state.estates.map((data, i) =>

                   

                <li key={i} onClick={this.goToPaymentInfo.bind(null, data)}>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      name="check"
                      id="exampleCheck"
                    />
                    <Label
                      className="checkBoxLablel"
                      for="exampleCheck"
                      check
                    >
                      <b>{data.name}</b>
                    </Label>
                  </FormGroup>
                </li>
                )}
                {/* <li>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      name="check"
                      id="exampleCheck2"
                    />
                    <Label
                      className="checkBoxLablel"
                      for="exampleCheck"
                      check
                    >
                       <b>Check me outertetetetetetetrterte</b>
                    </Label>
                  </FormGroup>
                </li> */}
              </ul>
            </div>
             &nbsp;
             &nbsp;   
             &nbsp;   
             &nbsp;   
             &nbsp;   

            <div className="homeBottomText">
                <p>If you need assistance managing your estates or payments please reach out to <a  target="_blank" href='mailto:hello@estateclarity.com'>hello@estateclarity.com</a></p>
            </div>
            </div>
          </React.Fragment>
        );
    }
}
const mapDispatchToProps = (dispatch)=>({
  payment :(data)=>dispatch(paymentData(data))
}
 
)
const mapStateToProps = (state)=>{
// console.log(state)
return {}
}

export default connect(mapStateToProps,mapDispatchToProps)(Home)