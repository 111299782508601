import React, { Component }from 'react';

import { Col, Row, Button, Form, FormGroup, Label, Input, FormText, CustomInput } from 'reactstrap';

import NavbarHeader from './navbarHeader';

import {apiService} from '../services/api';

import {connect} from 'react-redux'
import {paymentData} from '../actions/actions'
import { Spinner } from 'reactstrap';

import {estateSend} from '../actions/actions';

import {
    BrowserRouter ,
    Route,
    Switch
  } from "react-router-dom";

  var result = [];

 class ManageEstate extends Component{
    constructor(props){
        super(props)
        this.state = {
          estates:[],
          loaderShow:false,
          count:'',
          userName:''
        }

    }

    componentDidMount(props){
        var cu = JSON.parse(localStorage.getItem('curruntUser'));

        var CurrentUserName = JSON.parse(localStorage.getItem('curruntUserName'));

      this.setState({userName:CurrentUserName});
    
        if(!cu){
                this.props.history.replace('/');
        } 

         this.getAllEstates();
    }

    getAllEstates = () => {
      this.setState({loaderShow:true});
      apiService.getEstatesFromDb((res) => {
        if(res){
        // console.log("test1..........,", res['data'].data);
            // result = res['data'].data.find( data => data.is_paid_user == true );
            result = res['data'].data.filter((each)=>each.is_paid_user==true)

          // console.log("result", result);
          // var newData=[];

          // newData.push(result);
            this.setState({estates:result});
           
            // console.log(this.state.estates);
              this.setState({count:this.state.estates.length});

              // console.log("count----", this.state.count);

           
            this.setState({loaderShow:false});
        }
      });
    }



    goToPaymentInfo = (data) =>{
      // console.log(this.props.estateDataSend(this.state.estates));

      this.props.estateDataSend(data);
       
        this.props.history.replace('/cancelSubscription');
        
    }

    render(){

      // console.log("count", this.state.count);
        return (
            
            <React.Fragment>
            <NavbarHeader params={this.props} />
            <div className="payment_main_div"> 
            <div className="userHomeText">
              <p>Hi {this.state.userName},</p>
              <p>
                You're a collaborator in {this.state.count} paid estates.To manage either of these estates, select one below.
              </p>
            </div>
            <div>
            {this.state.loaderShow && <Spinner size="sm" color="primary" />}
            </div>
            <div className="checkboxInput">
              <ul>
             
                {this.state.estates.map((data, i) =>

                 

                <li key={i} onClick={this.goToPaymentInfo.bind(null, data)}>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      name="check"
                      id="exampleCheck"
                    />
                    <Label
                      className="checkBoxLablel"
                      for="exampleCheck"
                      check
                    >
                      <b>{data.name}</b>
                    </Label>
                  </FormGroup>
                </li>
                )}
              </ul>
            </div>
             &nbsp;
             &nbsp;   
             &nbsp;   
             &nbsp;   
             &nbsp;   

            <div className="homeBottomText">
                <p>If you need assistance managing your estates or payments please reach out to <a  target="_blank" href='mailto:hello@estateclarity.com'>hello@estateclarity.com</a> </p>
            </div>
            </div>
          </React.Fragment>
        );
    }




}

const mapStateToProps = (state) =>{
  // console.log(state)
  return state
}


const dispatchToProps = (dispatch) =>({
  estateDataSend :(data)=>dispatch(estateSend(data))
})

export default connect(null, dispatchToProps)(ManageEstate)