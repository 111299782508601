import {pass_to_paymentInfo, pass_to_checkoutForm, pass_to_cancelSubscription} from './actionTypes'


export const paymentData=function(data){
    return {type:pass_to_paymentInfo,data}
}

export const amountSend = function(data){
    return {type:pass_to_checkoutForm, data}
}

export const estateSend = function(data){
    return {type:pass_to_cancelSubscription, data}
}