import React, { Component} from 'react';
import NavbarHeader from './navbarHeader';

import BackArrow from '../images/backArrow.png';
import { Col, Row, Button, Form, FormGroup, Label, Input, FormText, CustomInput } from 'reactstrap';
 
import Cleave from 'cleave.js/react';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from './checkoutForm';
// import Stripe from 'stripe';

// const stripe = new Stripe('sk_test_GMtdEyvIYWLZlnJTnEzGZ7o0');



export default class CardDetails extends  Component{

constructor(props, context){
    super(props, context);
    this.onCreditCardChange = this.onCreditCardChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.submit = this.submit.bind(this);

    this.state = {
      'email':''
    }

    
}

onToken = (token, addresses) => {
  
}

// componentDidMount(props){
//   var cu = JSON.parse(localStorage.getItem('curruntUser'));

//   if(!cu){
//           this.props.history.replace('/');
//   } 
// }

goBackHandle = () =>{
  // console.log(this.props.status);
    // this.props.history.push('/paymentInfo');
    this.props.routePath(this.props.status);

}

onCreditCardChange(event) {
  // formatted pretty value
  // console.log(event.target.value);

  // raw value
  // console.log(event.target.rawValue);
}

onDateChange(event) {
  // console.log(event.target.value);
}

submit = (event)  =>{

  event.preventDefault();

  // console.log(this.state.email);

  
  
}

    render(){
      // console.log(this.props);
      // console.log(this.props.value);
        return (
          <React.Fragment>
            {/* <NavbarHeader /> */}
            
            <div className="payment_main_div">
              <div className="backButton">
                <button onClick={this.goBackHandle}>
                  <img src={BackArrow} /> Back
                </button>
              </div>
              <div className="userHomeText">
                <p>
                 {this.props.value==4 && 'With the Monthly Billing plan you will be charged $3.99 each month.'} {this.props.value==24 && 'With the Annual Billing plan you will be charged $23.88 each year.'}
                </p>
              </div>
              <div className="mainHeadingBillingInfo">
                <h5>Billing Info</h5>
              </div>
              <Form onSubmit={this.submit}>
                {/* <FormGroup className="formInput">
                  <Label for="exampleEmail">EMAIL ADDRESS</Label>
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="Your Email Address"
                    onChange={(e) =>this.setState({'email':e.target.value})}
                    value={this.state.email}
                  />
                </FormGroup> */}

                {/* <FormGroup className="formInput">
                  <Label for="examplePassword">CARD NUMBER</Label>
                  <Cleave placeholder="Enter your credit card number"
                  className="expireDate"
                options={{creditCard: true}}
                onFocus={this.onCreditCardFocus}
                onChange={this.onCreditCardChange}  />
                </FormGroup>

                <FormGroup className="formInput">
                  <Label for="expiryDate">EXPIRE DATE</Label>
                  <Cleave
                  className="expireDate"
                    placeholder="Enter date"
                    options={{ date: true, datePattern: ["m", "Y"] }}
                    onChange={this.onDateChange}
                  />
                </FormGroup>

                <FormGroup className="formInput">
                  <Label for="exampleCVV">CVV</Label>
                  <Input
                    type="number"
                    name="cvv"
                    id="CVV"
                    placeholder="CVV"
                  />
                </FormGroup> */}

{/* <FormGroup className="formInput">
                  <Label for="exampleZip">ZIP/POSTAL CODE</Label>
                  <Input
                    type="number"
                    name="zip"
                    id="zipcode"
                    placeholder="Enter Zip/Postal Code"
                  />
                </FormGroup> */}

               
{/* testing */}
{/* <StripeProvider apiKey="pk_test_z8xGk703Vc7rmGGw1oPMVuie">
        <div className="example">
          <Elements>
            <CheckoutForm />
          </Elements>
        </div>
      </StripeProvider> */}
{/* production */}
      <StripeProvider apiKey="pk_test_mWOTT2YZV479AvBaK9hIxwe3">
        <div className="example">
          <Elements>
            <CheckoutForm/>
          </Elements>
        </div>
      </StripeProvider>

     

                {/* <div className="userHomeText">
                <p>
                 By cliking the pay button on this page, i agree to the <span className="change_color">terms and services.</span>
                </p>
              </div> */}

              <div>
              

              
                {/* <button className="payBtn">
                  Pay
                </button> */}
              </div>
              </Form>
            </div>

            
          </React.Fragment>
        );
    }
}

