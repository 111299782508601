import React, {Component} from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input, FormText, CustomInput } from 'reactstrap';

import NavbarHeader from './navbarHeader';
import BackArrow from '../images/backArrow.png';
import CardDetails from './cardDetails';
import {connect} from 'react-redux';

import { amountSend } from '../actions/actions';
import * as moment from 'moment';


class PaymentInfo extends Component{

  constructor(props){
    super(props)

    this.state={
      monthlyPayment:false,
      yearlyPayment:false,
      paymentInfoHide:true,
      value:'',
      status:0
    }
  }

  componentDidMount(props){
    var cu = JSON.parse(localStorage.getItem('curruntUser'));

    if(!cu){
            this.props.history.replace('/');
    } 
}

    goBackHandle = () =>{
        // console.log(this.props);

        this.props.history.push('/home');
       
    }

    onPaymentHandler = ({status, value}) =>{

      // console.log("tt", value);
      if(status==0){
        this.setState({monthlyPayment:true, paymentInfoHide:false, value:value, status});
        // console.log(detail);
        
          // this.props.history.replace('/cardDetails');
      } else {
        this.setState({yearlyPayment:true, paymentInfoHide:false, value:value, status});
        // console.log(detail);
        
          // this.props.history.replace('/cardDetails');
      }

      this.props.payment({value, status})
     
    }

    routePath = (status) =>{
      // console.log(status);
      if(status==0){
        this.setState({monthlyPayment:false, paymentInfoHide:true})
      } else {
        this.setState({yearlyPayment:false, paymentInfoHide:true})
      }
      
    }



   
    render(){
      let {monthlyPayment,paymentInfoHide, value, yearlyPayment} = this.state;
      // console.log("monthlyPayment", monthlyPayment);
      // console.log("paymentInfoHide", paymentInfoHide);
      // console.log("yearlyPayment", yearlyPayment);

      // console.log("value", value);
      // console.log(this.props.estateReducer.data.is_paid_user,'check2')

        return (
          <React.Fragment>
            <NavbarHeader params={this.props}/>
            {this.state.paymentInfoHide && <div className="payment_main_div">                    
            <div className="backButton">
                <button onClick={this.goBackHandle}><img src={BackArrow}/> Back</button>
            </div>

            {this.props.estateReducer.data.is_paid_user == false &&  
            <div>
            <div className="awsome_billing_div">
                  <p>Awesome, Select a billing plan below for</p>
                   
                  <p><b>{this.props.estateReducer.data.name}</b></p>
                
                  <p>and we'll get you up and running in no time.</p>
            </div>

            <div className="check_box_payment">
            <FormGroup check onClick={this.onPaymentHandler.bind(null, {status:0, value:'4'})}>
                    <Input
                      type="checkbox"
                      name="check"
                      id="exampleCheck"
                    />
                    <Label
                      className="checkBoxLablel"
                      for="exampleCheck"
                      check
                    >
                      <b>Monthly Billing <span className="break_text"> $3.99 billed monthly</span></b>
                    </Label>
                  </FormGroup>
            </div>
            <div className="check_box_payment">
            <FormGroup check onClick={this.onPaymentHandler.bind(null, {status:1, value:'24'})}>
                    <Input
                      type="checkbox"
                      name="check2"
                      id="exampleCheck2"
                    />
                    <Label
                      className="checkBoxLablel"
                      for="exampleCheck2"
                      check
                    >
                     <b>Annual Billing <span className="change_color">Best Value!</span> <span className="break_text"> $1.99/mo ($23.88/year)</span></b>
                    </Label>
                  </FormGroup>
            </div>
            <div className="homeBottomText">
            <p>If you need assistance managing your estates or payments please reach out to <span className="change_color"><a  target="_blank" href='mailto:hello@estateclarity.com'>hello@estateclarity.com</a></span></p>
            </div>
            </div>
          }


            </div>} {this.state.monthlyPayment && <CardDetails test='test123' value={this.state.value} routePath={this.routePath} status={this.state.status}/>}
            {this.state.yearlyPayment && <CardDetails value={this.state.value} routePath={this.routePath} status={this.state.status}/>}

            { this.props.estateReducer.data.is_paid_user == true &&  <div className="payment_main_div">

            {/* <div className="backButton">
                <button onClick={this.goBackHandle}><img src={BackArrow}/> Back</button>
            </div> */}

            {this.props.estateReducer.data.payment_mode=='monthly' && <div className="awsome_billing_div">
              <p><b>{this.props.estateReducer.data.name}</b> Estate is currently enrolled in the {this.props.estateReducer.data.payment_mode} Payment Plan.</p>
              <p>The next billing date is {moment(this.props.estateReducer.data.payment_date).add(1, 'month').format('MMMM Do YYYY, h:mm:ss a')}.</p>
            </div>}

            {this.props.estateReducer.data.payment_mode=='annual' && <div className="awsome_billing_div">
              <p><b>{this.props.estateReducer.data.name}</b> Estate is currently enrolled in the {this.props.estateReducer.data.payment_mode} Payment Plan.</p>
              <p>The next billing date is {moment(this.props.estateReducer.data.payment_date).add(1, 'year').format('MMMM Do YYYY, h:mm:ss a')}.</p>
            </div>}


            </div>}

          </React.Fragment>
        );
    }

} 

const mapStateToProps = (state) =>{
  // console.log(state)
  return state
}


const dispatchToProps = (dispatch) =>({
  payment :(data)=>dispatch(amountSend(data))
})
export default connect(mapStateToProps, dispatchToProps)(PaymentInfo) 
