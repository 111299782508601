import React, {Component} from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import logo from '../images/logo-2.png';
import axios from 'axios';
import Swal from 'sweetalert2';
import { apiService, serverBucketUrl } from '../services/api';
import { Spinner } from 'reactstrap';
export default class Login extends Component{

    constructor(props){
        super(props)
        this.state={
            controls:{
                email:{
                    elementType:'input',
                    elementConfig:{
                        type:'email',
                        placeholder:'Mail Address'
                    },
                    value:'',
                    validation:{
                        required:true
                    },
                    valid:false,
                    touched:false
                },

             password:{
                elementType:'input',
                elementConfig:{
                    type:'password',
                    placeholder:'Password'
                },
                value:'',
                validation:{
                    required:true
                },
                valid:false,
                touched:false
             }   
            },

            show:false
        }
    }

    componentDidMount(){
        var cu = JSON.parse(localStorage.getItem('curruntUser'));

        if(cu!==''&& cu!==null && cu!==undefined){

                this.props.history.replace('/Home');
        }
    }


    submitLoginHandler = (event) => {
        
        event.preventDefault();
       
        let currentUser = {
            email:this.state.email,
            password:this.state.password
        }

        if(currentUser.email && currentUser.password){
            this.setState({show:true}); 
            // console.log("currentUser", currentUser);
            apiService.login(this.state.email,this.state.password,  (res) => {

                // console.log(res);
             
                if(res.status == 200){

                   

                    // const Toast = Swal.mixin({
                    //     toast: true,
                    //     position: 'top-end',
                    //     showConfirmButton: false,
                    //     timer: 3000
                    //   });
                      
                    //   Toast.fire({
                    //     type: 'success',
                    //     title: 'Signed in successfully'
                    //   })
                    localStorage.setItem('curruntUser', JSON.stringify(currentUser));
                    localStorage.setItem('userId', JSON.stringify(res['data'].data.id));
                    localStorage.setItem('curruntUserName', JSON.stringify(res['data'].data.username));
    
                    localStorage.setItem('token', JSON.stringify(res['data'].data.token));
                    this.setState({show:false}); 
                    this.props.history.replace('/Home');
                   
                } else {
                    this.setState({show:false}); 
                      Swal.fire({
                        type: "error",
                        title: "Oops...",
                        text:
                          "Invalid Email or Password!",
                        confirmButtonColor: "#62A3F9"
                      });
                
                }
    
              });
        } else {
            this.setState({show:false}); 
            Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: 'Invalid Email or Password!',
                confirmButtonColor:'#62A3F9'
              })
        }
    }

    forgotPasswordHandler = () =>{
        apiService.forgotPassword((res)=>{

            // console.log(res);

        });
    }
    render(){
        return(
            <div>
            <div className="navbarHeader">
                <Navbar color="faded" light>
          <NavbarBrand href="/" className="mr-auto">
              <img  className="logoImg"   src={logo} alt="Not Found"/>
          </NavbarBrand>
        </Navbar>
        </div>
        <div className="payment_main_div">    
                <h2 className="signInText">Sign In</h2>
                <p className="mainText">If you haven't already created an account, please do so by <a>downloading Estate Clarity.</a> and creating your account.</p>
                <p className="mainText">if you have an account already, sign in here: </p>
                <div className="loginForm">
                    <Form onSubmit={this.submitLoginHandler}>
                        <FormGroup className="formInput">
                            <Label for="exampleEmail">Email</Label>
                            <Input type="email" name="email" id="exampleEmail" placeholder="Your Estate Clarity Email" onChange={(e)=>
                                 this.setState({email:e.target.value})}/>
                        </FormGroup>
                        <FormGroup className="formInput">
                            <Label for="examplePassword">Password</Label>
                            <Input type="password" name="password" id="examplePassword" placeholder="Your Estate Clarity Password"  onChange={(e)=> this.setState({password:e.target.value})}/>
                        </FormGroup>
                        <Button className="signInbtn"> {this.state.show && <Spinner size="sm" color="primary" />} Sign In</Button>
                       
                        {/* <div>
                        <h6 className="forgot" onClick={this.forgotPasswordHandler}>Forgot Password?</h6>
                        </div> */}

                    </Form>
                 </div>
           </div>
            </div>
        );
    }
}