import React, { Component } from 'react';
// import logo from './logo.svg';
import './App.css';
import Home from './components/home';
import Login from './components/login';
import PaymentInfo from './components/paymentInfo';

import CardDetails from './components/cardDetails';


import CancelSubscription from './components/cancelSubscription';

import ManageEstates from './components/manageEstates';
import {
  BrowserRouter ,
  Route,
  Redirect,
  withRouter,
  Switch,
  HashRouter 
} from "react-router-dom";
import Success from './components/success';


class App extends Component {
  render() {
    return (
      <div className="App">
       <HashRouter>
        <Switch>
          <Route path="/" exact  component={withRouter(Login)} />
          <Route path="/Home" component={Home}/>
          <Route path="/paymentInfo" component={PaymentInfo}/>
          <Route path="/manageEstates" component={ManageEstates}/>
          <Route path="/cancelSubscription" component={CancelSubscription}/>
          <Route path="/success" component={Success}/>

          <Redirect to="/"/>
        </Switch>
      </HashRouter>
      </div>
    );
  }
}

export default App;
