import React, {Component} from 'react';
import logo from '../images/logo-2.png';
import Swal from 'sweetalert2';

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';


export default class Example extends Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  logOutHandler = () => {

    // console.log(this.props);
    Swal.fire({
      title: 'Are you sure?',
      // text: "You want to Log Out?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#62A3F9',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Logout!'
    }).then((result) => {
      if (result.value) {
          
        Swal.fire({
          title:'Logout!',
          text:'You are logging out successfully',
          type:'success',
          confirmButtonColor:'#62A3F9'
        }
          
        )
          localStorage.removeItem('curruntUser');
          localStorage.removeItem('token');
          localStorage.removeItem('curruntUserName');
          localStorage.removeItem('userId');

          // console.log(this.props);
          
          this.props.params.history.replace('/');
      }
    });
  
  }

  goToManageEstates=() => {
    if(this.props.manage){
      this.props.manage.history.replace('/manageEstates');
    } else {
      this.props.params.history.replace('/manageEstates');
    }

        
  }

  render() {
    return (
        
      <div  className="navbarHeader"> 
        <Navbar color="faded" light>
          <NavbarBrand href="/" className="mr-auto">
              <img  className="logoImg"   src={logo} alt="Estate Clarity"/>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse isOpen={!this.state.collapsed} navbar>
            <Nav navbar className="bottomMenu">
              <NavItem>
                <NavLink href="/">Upgrade Free Estate</NavLink>
              </NavItem>
              <NavItem>
                <NavLink   onClick={this.goToManageEstates}>Manage Paid Estate</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={this.logOutHandler}>Logout</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}